<template>
  <div>
    <el-row> 
      <el-col :span="24" class="infoTit">
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==0"  class="tag bgRed titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===1 && goodsinfo.drugCategory==1"  class="tag bgGreen titColor radiusBox">OTC</span>
        <span  v-if="goodsinfo.otcType===0"  class="tag bgRed titColor radiusBox">Rx</span>
        <span  v-if="goodsinfo.newStatus==true||goodsinfo.newStatus=='true'"  class="tag bgGreen titColor">新品</span>
        <span  v-if="goodsinfo.medicalInsuranceType>0"  class="tag bgBlue titColor">医保</span>
        <span  v-if="goodsinfo.proprietary==1"   class="tag bgOrange titColor">自营</span>
       <span class="brandName">{{goodsinfo.brandName}} </span>
        <!-- {{ goodsinfo.name }} -->
        <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }} 
          <sub class="YSTit"  v-if="activityType==1">预售</sub>
        </span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg"  v-if="activityType==1">
        <!-- 预售 -->
        <span>{{ goodsinfo.subTitle }}</span>
        <!--   <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
         <!-- 限时购详情 -->
       <el-col :span="24" class="infoMsg"  v-if="activityType==2">
         <!-- <span>商品包含：{{ subTitle }}</span> -->
      </el-col>
    </el-row>
    <el-row v-if="activityType==2">
      <el-col :span="24" class="countdownBox">
        <span class="countdownMsg">限时抢购</span>
        <span class="countdownDiv">
            <ProCountDown :startTime="startTime" :endTime="endTime"></ProCountDown>
        </span>
      </el-col>
    </el-row>
    <el-row class="infoBox">
      <el-col :span="24" class="infoActivity" v-if="activityType==2">
        <span class="infoKey">活动说明:</span>
        <span class="infoDes">{{goodsinfo.description}}</span>
      </el-col>
      <el-col  class="UlBoxs"  :span="6"  v-if="activityType==1">

         <!-- 预售 -->
        <span class="infoKeysBox">预售价：</span>
        <!-- <span class="price"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
        <del>  {{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'':goodsinfo.memberPrice:''}}</del> -->
        <span class="price"  v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''"><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":priceMsg}}</span>
        <span class="price"  v-else><strong>￥</strong>{{ goodsinfo.price==="***"?"会员可见":goodsinfo.price }}</span>
        <del  v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''">{{goodsinfo.price!=="***"?Number(goodsinfo.price)>=Number(goodsinfo.memberPrice)?'￥'+Number(goodsinfo.price):goodsinfo.memberPrice:''}}</del>
       

      </el-col>
      <el-col :span="6" v-if="activityType==1">
        <span class="infoKey">参考价：</span>￥{{
          goodsinfo.price==="***"?"会员可见":goodsinfo.originalPrice
        }}</el-col
      >
      <el-col :span="6" v-if="activityType==1"><span class="infoKey">毛利率：</span>{{ goodsinfo.grossProfitMargin }}%</el-col>
      <el-col :span="6" v-if="activityType==1"><span class="infoKey">净赚：</span>￥{{ goodsinfo.price==="***"?"会员可见":goodsinfo.earnings }}</el-col>
      <el-col :span="24" class="infoActivity"  v-for="(item,index) in promotionMap" :key="index">
        <span class="infoKey" v-if="index==0">促销活动:</span>
        <span class="infoKey" v-else></span>
        <span class="infoTag">{{item.type}}</span>
        <span class="infoDes">{{item.name}}</span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row  :span="24"  class="infoParameter" v-if="activityType==1">
      <div><span class="infoKeys">商品编号:</span><span>{{goodsinfo.productSn }}</span></div>
       <div><span class="infoKeys">国药准字:</span><span>{{goodsinfo.approvalNo}}</span></div>
      <div><span class="infoKeys">药品规格:</span><span>{{goodsinfo.specification}}</span></div>
      <div><span class="infoKeys">件装规格:</span><span>{{goodsinfo.partSpecification}}</span></div>
      <div><span class="infoKeys">发货时间:</span><span>{{goodsinfo.presellDeliveryDate}}</span></div>
      <div><span class="infoKeys">医保类型:</span><span>{{goodsinfo.medicalInsuranceType===0?'非医保':goodsinfo.medicalInsuranceType===1?'甲类医保':'乙类医保'}}</span></div>
      <div><span class="infoKeys">剩余库存:</span>
        <!-- <span>{{goodsinfo.presellTotal-goodsinfo.presellUsage}}{{ goodsinfo.unit }}</span> -->
        <span v-if="Number(goodsinfo.presellTotal-goodsinfo.presellUsage)>1000">充足</span>
        <span v-else-if="Number(goodsinfo.presellTotal-goodsinfo.presellUsage)>1 && Number(goodsinfo.presellTotal-goodsinfo.presellUsage)<=1000">{{goodsinfo.presellTotal-goodsinfo.presellUsage}}{{ goodsinfo.unit }}</span>
        <span v-else-if="Number(goodsinfo.presellTotal-goodsinfo.presellUsage)<1">备货中</span>
      </div>
      <!-- <div>
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
          <span v-if="goodsinfo.quantity==-1 || goodsinfo.quantity< Number(goodsinfo.minOrderNum)">备货中</span>
          <span v-if="goodsinfo.quantity>1000">充足</span>
          <span v-if="goodsinfo.quantity<=1000 && goodsinfo.quantity>=Number(goodsinfo.minOrderNum)">{{goodsinfo.quantity}}{{goodsinfo.unit}}</span>
      </div> -->
      <div v-if="goodsinfo.quantity<1 && goodsinfo.deliveryTimeFormat"><span class="infoKeys">到货日期:</span><span>{{goodsinfo.deliveryTimeFormat}}</span></div>
      <div  @click="Download(goodsinfo.goodNum)"> <span class="infoKeys" >首营资料：</span> <strong class="downBtn">查看/下载</strong></div>
    </el-row>
    <el-row :span="24" class="DecVOBox" v-if="activityType==2 && goodsinfo.numPriceDetailVOList.length>0">
        <div class="DecVOLeft">
          <span><em>满降</em></span>
        </div>
        <div  class="DecVORight" >
          <p   v-for="(item, index) in goodsinfo.numPriceDetailVOList" :key="index">满{{item.num}}盒享{{item.price}}元/盒</p>
        </div>
      </el-row>
    <el-row  :span="24"  class="infoParameter"  v-if="activityType==2">
       <el-col :span="6">
        <span class="infoKey" style="text-indent: 30px;">限购价：</span
        ><span class="price">
          {{ goodsinfo.auditUserStatus===0?"***":comboPrice  | capitalize }}
           </span>
           <del class="del">{{goodsinfo.auditUserStatus===0?"***":goodsinfo.productArray[0].memberPrice  | capitalize}}</del>
        </el-col>
      <el-col :span="6">
        <span class="infoKeyss">限购<span class="price">{{goodsinfo.comboUserTotal}}</span>盒，已购<span class="price">{{goodsinfo.userUsage>0 ?goodsinfo.userUsage: 0}}</span>盒</span>
      </el-col>
    </el-row>
    <el-row class="infoNumBox" v-if="activityType==1">
        <!-- 预售 -->
        <el-row class="infoActivitysBox" v-if="goodsinfo.numPrice && goodsinfo.numPrice!=''">
          <span class="infoKey">预售活动</span>
          <el-col :span="24" class="infoActivitys" >
              <div  v-for="(item,index) in numPriceArr" :key="index" @click="checkFun(item,index)"  :class="keyNum!==index?'numPriceDesBtn':'numPriceDesBtn active'">
                <p>￥{{ Number(item.price).toFixed(2) }}</p>
                <p>{{item.num}}盒起</p>
              </div>
          </el-col>
        </el-row>


      <el-col :span="24">
        <span class="infoKeyLabel">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.minOrderNum<0?1:goodsinfo.minOrderNum"
          :max="goodsinfo.countRestrict==-1?
                          goodsinfo.presellTotal==-1?
                              Number(10000):Number(goodsinfo.presellTotal-goodsinfo.presellUsage)
                          :
                          goodsinfo.presellTotal==-1?
                              goodsinfo.countRestrict
                              :
                              Number(goodsinfo.presellTotal-goodsinfo.presellUsage-goodsinfo.countRestrict)>0
                              ?goodsinfo.countRestrict:Number(goodsinfo.presellTotal-goodsinfo.presellUsage)
                          "
          :step="goodsinfo.minOrderNum<0?1:goodsinfo.minOrderNum"
          step-strictly
        ></el-input-number>
        <span class="xgBox" v-if="goodsinfo.countRestrict > 0">
          * 单次限购<span>{{ goodsinfo.countRestrict }}</span>{{ goodsinfo.unit }} <span v-if="Number(goodsinfo.times)>0">，限购次数:{{ Number(goodsinfo.times-goodsinfo.cumsum)}}次</span>
        </span>
        <span class="xgBox" v-else>
          * 单次限购:<span>无限制</span>，限购次数:{{Number(goodsinfo.times)==-1?'无限': goodsinfo.times-goodsinfo.cumsum }}次
        </span>



      <div class="freightBox"><i class="el-icon-circle-check" aria-hidden="true"></i>满300元免运费</div>
      </el-col>
    </el-row>
    <el-row class="infoNumBox" v-else>
      <el-col :span="24">
        <span class="infoKeyLabel">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="goodsinfo.productList[0].perQuantity"
          :max="Number(maxNum)"
          :step="goodsinfo.productList[0].minOrderQuantity"
          @input="handleInput"
        ></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns"  v-if="activityType==1">
      <!-- 预售 -->
      <div :span="24">
        <span class="infoKey"></span>
        <button  :disabled="goodsinfo.times==-1?Number(goodsinfo.presellTotal-goodsinfo.presellUsage)==0:(Number(goodsinfo.times - goodsinfo.cumsum)==0 || Number(goodsinfo.presellTotal-goodsinfo.presellUsage)==0)" :class="(goodsinfo.times==-1?Number(goodsinfo.presellTotal-goodsinfo.presellUsage)==0:(Number(goodsinfo.times - goodsinfo.cumsum)==0 || Number(goodsinfo.presellTotal-goodsinfo.presellUsage)==0))?'grey addBtn':'addBtn'"  @click="BuyNowFun(num)">
           立即购买
        </button>
        <!-- <button v-if="goodsinfo.times==-1" class="shopBtn"  @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button v-else :disabled="Number(goodsinfo.times - goodsinfo.cumsum)==0" :class="Number(goodsinfo.times - goodsinfo.cumsum)==0?'grey shopBtn':'shopBtn'"  @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button v-if="goodsinfo.times==-1"  class="addBtn" @click="addCartFun(goodsinfo)">加入购物车</button>
        <button v-else :disabled="Number(goodsinfo.times - goodsinfo.cumsum)==0" :class="Number(goodsinfo.times - goodsinfo.cumsum)==0?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">加入购物车</button> -->
      </div>
    </div>

     <div class="infoBtns"  v-else>
       <!-- 限时抢购 判断-->
        <div :span="24">
          <span class="infoKey"></span>
          <button :disabled="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1 ||TimeBuyFlag" :class="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1 ||TimeBuyFlag?'grey addBtn':'addBtn'"  @click="saveFun(num)">
            立即购买
          </button>
        </div>
      </div>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.price{
          font-family: smallYuan;
        }
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    position: relative;
    .brandName{
      font-weight: bolder;
      color: #333;
    }
    .YSTit{
        color:#fff;
        font-weight: bold;
        font-size: 13px;
        position: absolute;
        background: #a378eb;
        border-radius:10px;
        padding: 0 8px;
        margin-left: 10px;
        height: 20px;
        line-height: 20px;

      }
    .tag{
        width:42px;
        text-align: center;
          height: 20px;
          // padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:50%;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .DecVOBox{
    margin-left:35px ;
    margin-bottom: 10px;
    .DecVOLeft{
      float: left;
      font-weight: bold;
      em {
        border-radius:2px;
        padding:2px 6px;
        font-size:15px;
        background: crimson;
        color: #fff;
        margin-right: 10px;
      }
    }
    .DecVORight{
      float: left;
      p{
        margin-bottom: 5px;
        font-size:14px;
        color: crimson;
      }
    }
  }
  .infoMsg {
    // color: @theme;
    color: #333;
    font-size: 14px;
    line-height: 30px;
    // font-weight: bold;
        margin-top: 5px;
  }
  span.infoKey {
      // width: 86px;
    text-align: left;
    display: inline-block;
    color: #333333 ;
    font-size: 14px;
  }
  span.infoKeysBox {
    width:68px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
    span.infoKeyLabel{
    width: 86px;
    text-align: left;
    display: inline-block;
    color: #333333 ;
    font-size: 14px;
  }
  .downBtn{
    cursor: pointer;
    background: #0EA0FF;
    color:#fff;
    padding:2px 5px;
    &:hover{
      background: @theme;
    }
  }
  .freightBox{
    margin-top: 20px;
    font-size:16px;
    i{
      padding-right: 10px;
      color: #ff3333;
      font-weight: bold;
      font-size:16px;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
    .xgBox{
      padding-left: 20px;
      display: inline-block;
      color: #FA7816FF;
      font-size:13px;
      font-weight: bold;
    }
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 900;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: @theme;
        color: #fff;
        margin-left: 20px;
        border: 1px solid @theme;
        outline: none;
      }
      &.shopBtn {
        background: @shopBtnTheme;
        color: @theme;
        border: 1px solid @theme;
        outline: none;
      }
      &.grey{
        background: #ababb0;
          color: #fff;
          border: 1px solid #ababb0;
      }
    }
  }
  .infoParameter {
    // padding: 0px 10px 25px;
    // border-bottom: 2px dotted #cac3c3;
    // margin-bottom: 20px;
    // color: #333;
     padding: 0px 10px 25px;
    border-bottom: 2px dotted #cac3c3;
    margin-bottom: 25px;
    color: #333;
     font-size: 12px;
    .infoKeys{
      color: #333;
      font-size: 12px;
    width: 66px;
    display: inline-block;
    }
    .del{
       color: #666;
      font-size:12px;
    }
     span.price {
      color: @theme;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    div{
      float: left;
      width: 50%;
       margin-bottom: 15px;
        &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;
     
    // }
  }
  .countdownBox{
    margin-bottom: -10px;
    background: linear-gradient(to right, #f43c38, #f1f1f1);
    height: 40px;
    line-height: 40px;
      .countdownMsg{
          padding-left: 20px;
          color: #fff;
          font-size: 16px;
      }
      .countdownDiv{
        float: right;
         padding-right: 20px;
      }
    }
  .infoBox {
    background: #FFF8F3;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top:10px;
    
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom:25px;
      }
    }
   
    span.price {
      color: @theme;
      padding-right:10px;
      font-size:14px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.infoTag {
      border: 1px solid @theme;
      color: @theme;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
    }
    span.infoDes {
      color: #666;
    }
  }
  .infoActivitysBox{
    padding: 0px 10px;
    margin-bottom: 25px;
    .infoKey{
        line-height: 24px;
        text-align: center;
        display: inline-block;
        color: #333;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    .infoActivitys{
      .numPriceDesBtn{
        text-align: center;
        float: left;
        padding:3px 28px  5px;
        border: 1.5px solid #E6E6E6FF;
        background:#F5F5F5FF ;
        border-radius: 5px;
        margin-right: 8px;
        cursor: pointer;
        &.active{
          border: 1.5px solid #FF3333FF;
          background:#FEE6E2FF  ;
          p:nth-child(2){
            color: #FF3333FF;
            font-size: 12px;
          }
        }
        p{
          line-height:16px;
          &:nth-child(1){
            color: #FF3333FF;
            font-size: 14px;
            font-weight: bold;
            &::first-letter {
                font-size:80%;
                font-weight: bolder;
            }
          }
          &:nth-child(2){
            color: #666;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
<script>
const ProCountDown = () => import("components/product/ProCountDown.vue");
import {AddCart,DownloadFirstTest} from "api/product.js"
import { mapState,mapMutations,mapGetters} from "vuex";
export default {
  name: "GoodsInfo",
  data() {
    return {
      // num:0,
      stepNum:1,
      num:1,
      promotionMap:[],
      subTitle:'',
      subTotal:0,
       endTime:'',
        startTime:'',
        TimeBuyFlag:false,
        comboPrice:0,
        maxNum:0,

        keyNum:-1,
        numPriceArr:[],
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
    ActivityType:{
      type:Number
    }
  },
  components: {
    ProCountDown
  },
  computed: {
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    })
  },
  methods: {
    checkFun(item,index){
        this.keyNum=index
        // if(index==this.keyNum){
        //   this.keyNum=-1
        // }else{
        //   this.keyNum=i
        // }
        this.priceMsg=Number(item.price).toFixed(2)
        this.num=item.num
        this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.priceMsg))*this.num).toFixed(2)+'元';
    },
    handleInput(productNum) {
      if(productNum>=this.goodsinfo.productList[0].perQuantity){
         let productNums=this.goodsinfo.productList[0].perQuantity +Math.ceil(Number(productNum-this.goodsinfo.productList[0].perQuantity) / Number(this.goodsinfo.productList[0].minOrderQuantity)) * Number(this.goodsinfo.productList[0].minOrderQuantity)
        this.$nextTick(function () {
              // 将输入值调整为步长值的倍数
                this.num= productNums
          }) 
        return false
      }
    },
    // 预售单独成单
    BuyNowFun(num){
      let datailsArr=[];
      datailsArr.push({
        goodNum : this.goodsinfo.goodNum,
        goodsCount:num,
        goodsId :this.goodsinfo.id,
        goodsType :8,
        isRecentExpiration : 0,
        promotionId:this.goodsinfo.promotionId,
      });

      let datas={
        details: datailsArr,
        orderType:3,
      }
      console.log(datas)
      sessionStorage.setItem('AdvanceSale',JSON.stringify(datas))
      // // 单独成单，跳订单结算,query:{ids:moreIds}}
      this.$router.push({ name: "GoodsAmount",query:{id:"AdvanceSale"}});
    },

    // 限时购单独成单
     saveFun(num){
      let datailsArr=[];
      var newStr;

      console.log()
      if(/[\u4E00-\u9FA5]+/.test(this.comboPrice)){
        newStr = this.comboPrice.replace(/起/g,'');
        console.log(newStr)
      }else{
        newStr = this.comboPrice
      }

      this.goodsinfo.productVOList.forEach(item => {
         datailsArr.push({
            goodNum : item.goodNum,
            // goodsCount:item.minOrderNum*num,
            goodsCount:this.num,
            goodsId :item.id,
            goodsType :9,
            isRecentExpiration : 0,
            // originalPrice :item.price,
            // originalPrice :this.comboPrice,
            originalPrice :newStr ,
         })
      });
      let datas={
        details: datailsArr,
        orderType:7,
        promotionId:this.goodsinfo.id,
        purchaseNum :num,
      }
      console.log(datas)
      sessionStorage.setItem('comBo',JSON.stringify(datas))
      // // 单独成单，跳订单结算,query:{ids:moreIds}}
      this.$router.push({ name: "GoodsAmount",query:{id:"combo"}});
    },
     Download(goodNum){
        // 显示loading
        this.$loading({ fullscreen: true ,background:'#ffffff45'})
      //  alert(goodsId)
         DownloadFirstTest(goodNum).then(res=>{
           if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
                // 关闭loading
                this.$loading().close();
           }else{
            let href=res.data.data;
              // 关闭loading
            this.$loading().close();
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
          
     
        })
    },
     ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
    handleChange(value) {
      // value
      if(this.activityType==1){
        // 预售
        this.$nextTick(() => {
          // console.log('dangqian',this.num)
          // console.log('dangqian',this.numPriceArr)
          if (this.numPriceArr.length==0) {
            return true
          }
          if(this.num<this.numPriceArr[this.numPriceArr.length-1].num){
            this.priceMsg=Number(this.numPriceArr[this.numPriceArr.length - 1].price).toFixed(2)+'起'
            this.keyNum=-1
            // console.log('this.goodsinfo.memberPrice',this.goodsinfo.memberPrice)
            this.NowPriceMSG=''
          }else{
            for(var index = 0; index < this.numPriceArr.length; index++) {
                if(this.numPriceArr[index].num<= this.num){
                  this.keyNum=index;
                  this.priceMsg= this.numPriceArr[index].price;
                  // console.log('this.goodsinfo.memberPrice',this.goodsinfo.memberPrice)
                  this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.priceMsg))*this.num).toFixed(2)+'元';
                  return true
                }
            }
          }
        
        })
      }

      if(this.goodsinfo.numPriceDetailVOList==undefined){
        return false
      }
      this.goodsinfo.numPriceDetailVOList.forEach(item=>{
        if(value>=item.num){
          this.comboPrice=Number(item.price).toFixed(2) 
        }else{
          return false
        }
      }) 
    },
   addCartFun(data) {
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // {"isRecentExpiration":0,"quantity":10,"productId":1}
      const addcartDate=[];
      addcartDate.push({
        // isRecentExpiration:data.isRecentExpiration,
        isRecentExpiration:0,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
        cartType : 2,
        deliveryTime:data.presellDeliveryDate,// 到货日期   发货时间:
        promotionId:data.promotionId//促销ID
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });
            let count=this.num
            this.setCarGoodsNum(count);
        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    },
    immediateBuy:function(data) {

      console.log('jaiuhuhih',data)
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'未认证用户不能购买',
              type:"warning"
          })
          return false
      }
      const addcartDate=[];
      addcartDate.push({
        isRecentExpiration:0,
        quantity:this.num,
        productId:data.id,
        goodNum:data.goodNum,
        cartType : 2,
        deliveryTime:data.presellDeliveryDate,// 到货日期   发货时间:
        promotionId:data.promotionId//促销ID
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });
            let count=this.num
            this.setCarGoodsNum(count);
            this.$router.push({ name: "Cart" });

        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    }
  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
    this.activityType=this.ActivityType
    if(this.activityType==1){
      // 预售
      if(this.goodsinfo.numPrice && this.goodsinfo.numPrice!=''){
          this.numPriceArr=JSON.parse(this.goodsinfo.numPrice)
          // 倒序排序
          this.numPriceArr.sort(function(a, b) {  
            return b.num - a.num;  
          });  
          console.log(this.numPriceArr);
          this.priceMsg=Number(this.numPriceArr[0].price).toFixed(2)+'起'
          if(this.numPriceArr[this.numPriceArr.length-1].num==this.goodsinfo.minOrderNum){
            this.keyNum=this.numPriceArr.length-1
            this.priceMsg=Number(this.numPriceArr[this.numPriceArr.length-1].price).toFixed(2)
            this.NowPriceMSG='预计省'+((Number(this.goodsinfo.memberPrice) - Number(this.numPriceArr[this.numPriceArr.length-1].price))*this.goodsinfo.minOrderNum).toFixed(2)+'元';
          }
        }
        this.num=this.goodsinfo.minOrderNum


        if(this.goodsinfo.minOrderNum<0){
          this.num=1
        }else{
          this.num=this.goodsinfo.minOrderNum
        }
        this.promotionMap=this.goodsinfo.promotionMap
    }else{
      // 限时购详情
      console.log('限时购详情',this.goodsinfo)
      console.log("活动剩余总库存",this.goodsinfo.comboTotal -   this.goodsinfo.comboUsage )
      console.log("个人限购剩余量",this.goodsinfo.comboUserTotal-this.goodsinfo.userUsage)
      //  userUsage  50
      //  comboTotal: 1000
      //   comboUsage: 50
      //   comboUserTotal: 200
    //  活动剩余总库存：  this.goodsinfo.comboTotal -   this.goodsinfo.comboUsage 
    // 个人限购剩余量  this.goodsinfo.comboUserTotal-this.goodsinfo.userUsage
    if( Number(this.goodsinfo.comboTotal - this.goodsinfo.comboUsage )>Number(this.goodsinfo.comboUserTotal-this.goodsinfo.userUsage)){
        this.maxNum=Number(this.goodsinfo.comboUserTotal-this.goodsinfo.userUsage)
    }else{
       this.maxNum= Number(this.goodsinfo.comboTotal - this.goodsinfo.comboUsage )
    }
        

    this.promotionMap=this.goodsinfo.promotionMap         
this.comboPrice=this.goodsinfo.comboPrice 



if(this.goodsinfo.numPrice && this.goodsinfo.numPrice!=''){
            this.numPriceArr=JSON.parse(this.goodsinfo.numPrice)
            // 倒序排序
            this.numPriceArr.sort(function(a, b) {  
              return b.num - a.num;  
            });  
            console.log(this.numPriceArr);
            this.comboPrice=Number(this.numPriceArr[0].price).toFixed(2)+'起'
            // goodsinfo.productList[0].perQuantity          goodsinfo.minQuantity
            if(this.numPriceArr[this.numPriceArr.length-1].num==this.goodsinfo.productList[0].perQuantity){
              this.comboPrice=this.numPriceArr[this.numPriceArr.length-1].price
            }
          }


         this.goodsinfo.productList.forEach(item=>{
            this.subTitle+=item.name+item.perQuantity+item.unit+','
          })
          this.goodsinfo.productVOList.forEach(item=>{
            this.subTotal+=item.originalPrice*item.minOrderNum
          })
          this.subTitle=this.subTitle.substring(0,this.subTitle.length-1)
          this.num=1,
          console.log(this.goodsinfo.createTime)
          this.endTime=this.goodsinfo.endDate
          this.startTime=this.goodsinfo.beginDate

            let date = new Date();
           let now = date.getTime();
           let  startTime=new Date(this.startTime).getTime()-now;
           if(startTime>0){
            //  还未开始
             this.TimeBuyFlag=true 
           }else{
              this.TimeBuyFlag=false
           }
          // this.endTime=moment(new Date(Date.now()+10*1000)),
          // this.startTime=moment(new Date(Date.now()))
    }
  
  },
};
</script>
